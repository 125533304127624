.con-test{
    display: flex;
    flex-direction: column;
    gap: 20px;
    
}

.type-exams{
    display: flex;
    gap: 40px;
}
.type-exam{
    display: flex;
    gap: 5px;
    flex-direction: column;
}
.type-exam strong{
    font-weight: 400;
    cursor: pointer;
    font-size: large;
}
.under-line{
    width: 80%;
    height: 4px;
    border-radius: 2px;
    background-color: var(--color-primary);
}
.con-test-papers{
    width: max-content;
    
    
   display: flex;
   gap: 25px;
   justify-content: flex-start;
   flex-direction: row;
   
}
.test-papers{
    padding: 20px;
    border-radius: 10px;
    display: flex;
    background-color: white;
    height: max-content;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
}
.test-paper{
    display: flex;
    width: max-content;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
}
.test-details{
    display: flex;
    position: relative;
    justify-content: flex-start;
    flex-direction: column;
}
.test-details strong{
    font-size: medium;
}
.test-details p{
    color: grey;
    margin: 0;
}
.test-details strong{
    margin: 0;
}
.start-btn{
    width: max-content;
    display: flex;
    font-size: medium;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 5px;
    background-color: var(--color-primary);
    height: 80%;
}
.test-paper-attempts{
    display: flex;
    justify-content: center;
    width: max-content;
    height: 100px;
    background-color:white;
}
.tp-attempt{
    display: flex;
    width: fit-content;
    height: 100px;
    gap: 10px;
    overflow-y: scroll;
    flex-direction: column;
}
.tp-attempt .attempt-info{
    display: flex;
    height: max-content;
    width: max-content;
    gap: 15px;
    flex-direction: row;
}
.test-paper .actions{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
}

@media screen and (max-width:700px) {
    .con-test h2{
        margin: 0;
        padding: 0;
    }
    .con-test{
        padding: 10px;
    }
    .con-test h2{
        width: max-content;
    }
    .con-test-papers{
        width: max-content;
    }
   
    .test-paper{
        flex-direction: column;
        gap: 15px;
    }
    .test-paper .actions{
        margin: 0;
        width: 100%;
        display: flex;
        align-items: flex-start;
        gap: 10px;
        justify-content: flex-start;
    }
}
