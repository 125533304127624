.res_nav{
    background: rgba(0, 0, 0);
    width: max-content;
    display: block;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 20px;
    margin: auto auto;
    display: flex;
    top: 1.5vh;
    height: 97vh;
    flex-direction: column;
    gap: 0.8rem;
    border-radius:5px;
    backdrop-filter: blur(15px);
}

.res_nav a{
    background: transparent;
    padding: 1rem;
    border-radius:5px ;
    gap: 10px;
    display: flex;
    color: rgba(255,255,255,0.6);
    font-size: 2rem;
}
.res_nav a p{
    font-size: 18px;
    color: white;
}
.res_nav a:hover{
    background: rgba(0,0,0,0.3);
}
.res_nav a.active{
    background: #515da1;
    border-radius: 10px;
    border:3px solid #2B3674 ;
    color: white;
}