
.home_courses{
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-content: center;
    height: max-content;
}
.box_courses{
    width: 70%;
    display: flex;
    flex-direction: column;
}
.courses{
    display: flex;
    gap: 50px;
    flex-direction: row;
}
.home_course{
    padding-bottom: 10px;
    display: flex;
    box-shadow: 1px 2px 3px 4px rgba(184, 182, 182, 0.4);
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    height: max-content;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}
.home_course img{
    object-fit: cover;
    height: 100px;
}
.home_course h4,strong{
    margin: auto;
}
.course-btn{
    padding: 4px;
    margin: auto;
    width: max-content;
    color: white;
    border-radius: 6px;
    cursor: pointer;
    background-color: rgb(74, 74, 225);
}