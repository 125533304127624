.page-cpq{
    width: 100%;
    pad: 10px;
    height: 100%;
}
.box-btn-ap{
    display: flex;
    font-size: medium;
    font-weight: 500;
    width: max-content;
    cursor: pointer;
    position: absolute;
    bottom: 70px;
   
    justify-content: center;
    align-items: center;
    gap: 15px;
}
#addp-ic{
    color: var(--color-primary);
   scale: 2;
}
/* Spinner.css */
.spinner-cir {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  


@media  screen and (max-width:700px) {
   .box-btn-ap{
    left: 20px;
   }
}