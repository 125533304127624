.spinner {
    margin: 0 auto;
    width: max-content;
    position: absolute;
    top: 50vh;
    left: 38vw;
    text-align: center;
  }
  .spinner h1{
    font-size: medium;
    color: var(--color-primary);
  }
  .bounce1, .bounce2, .bounce3 {
    width: 12px;
    height: 12px;
    background-color: var(--color-primary);
    border-radius: 100%;
    display: inline-block;
    animation: bounce 1.4s infinite ease-in-out;
  }
  
  .bounce1 {
    animation-delay: -0.32s;
  }
  
  .bounce2 {
    animation-delay: -0.16s;
  }
  
  @keyframes bounce {
    0%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-18px);
    }
  }
  