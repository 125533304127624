.main-ques{
    display: flex;
    width: 100%;
    height: max-content;
    flex-direction: column;

}
.main-ques ::-webkit-scrollbar{
    width: 0;  
    background: transparent; 
}
h1,h2,h3,h4,h5,h6,strong{
    margin: 0;
    padding: 0;
}
.s{
    height: 100vh;
    display: flex;
    justify-content: center;
    width: 100vh;
}
.lay{
    position: fixed; 
    top: 0;
    z-index: 999;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(190, 182, 182, 0.5); 
    display: block;
}
.popup{
    position: fixed;
    top: calc((100% - 400px)/2);
    left: calc((100% - 400px)/2);
    height: max-content;
    overflow-x: hidden;
    background-color: var(--color-paper);
   display: flex;
   
   flex-direction: column;
   
    width: 400px;
}
.popup .h-cr{
    padding: 8px;
    display: flex;
    justify-content: space-between;

}
.h-cr h3{
    color: white;
}
.cross{
    width: 40px;
    cursor: pointer;
    justify-content: center;
    display: flex;
    border-radius: 20px;
    height: 40px;
    background-color: white
}
.main-ques .heading{
    padding: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: var(--color-paper);
    color: white;
}
.heading h3{
    margin: 0;
    padding: 0;
    color: white;
  
}
.box-ques{
    display: flex;
}
.ques-leftp{
    padding-left: 10px;
    margin-top: 10px;
    gap: 10px;
    overflow-y: scroll;
    width: 75%;
    display: flex;
    height: auto;
    flex-direction: column;
}
.ques-rightp{
    margin-top: 10px;
    overflow-y: scroll;
    display: flex;
    gap: 20px;
    flex-direction: column;
    min-height: 100vh;
    height: max-content;
    left: 80%;
    right: 20px;
    position: fixed;
}
.ques-rightp .stu-details{
    display: flex;
    box-shadow: 1px 2px 3px 4px rgba(184, 182, 182, 0.4);
    padding: 10px;
    justify-content: center;
    align-items: center;
    background-color: aliceblue;
    border: 2px solid black;
    gap: 10px;
}
.stu-details .stu-img{
    height: 80px;
    width: 80px;
    border-radius: 50%;
    overflow: hidden;
}

.stu-details .stu-data{
    display: flex;
    padding-left: 0px;
    background-color: aliceblue;
    flex-direction: column;
}
.stu-details .timer{
    margin: 0px;
    padding: 0px;
}
.ques-secs{
    display: flex;
    gap: 20px;
}
.ques-sec{
    display: flex;
    cursor: pointer;
    color: white;
    border-radius: 5px;
    align-items: center;
    background-color: var(--color-paper);
    padding: 5px 10px;
}
.ques-sec:hover{
    box-shadow: 1px 2px 3px 4px rgba(184, 182, 182, 0.4);
}
.allsec{
    background-color: rgb(6, 6, 115);
}
.ques-mainbox{

    border: 2px solid black;
    display: flex;
    flex-direction: column;
}
.qno{
    display: flex;
    padding:0 15px ;
    width: 100%;
    justify-content: space-between;
    background-color: var(--color-paper);
    color: white;
}
.question-box{
   
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.ques-img{
    display: flex;
    object-fit: cover;
    justify-content: center;
    width: 100%;
}
.ques-img img{
    display: flex;
    height: 300px;
    margin: auto;
    width: 500px;
}
.question-box .ques{
    width: 80%;
    color: black;
    font-size: medium;
    font-weight: 500;
    margin-left: 20px;
    display: flex;
}
.options{
    padding-bottom: 10px;
    width: 100%;
    position: relative;
}
.form{
    width: 100%;
    position: relative;
    display: flex;
    align-content: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}
.r{
    width: max-content;

}
.option{
    width: 100%;
    display: flex;
    gap: 20px;
    position: relative;
    justify-content: baseline;
    align-items: baseline;
    margin-left: 20px;
    height: 30px;
   
}
.nav-btns-ques{
    display: flex;
    gap: 20px;
}
.nav-btn-que{
    display: flex;
    cursor: pointer;
    color: white;
    border-radius: 5px;
    align-items: center;
    background-color: var(--color-paper);
    padding: 5px 10px;
}
.nav-btn-que:hover{
    background-color: rgb(6, 6, 115);
}
.ques-palette{
    border: 2px solid black;
    display: flex;
    height: fit-content;
    min-height: 300px;
    height: max-content;
    flex-direction: column;
    background-color: aliceblue;
}
.ques-palette .p-head,.no-ques .p-head{
    font-weight: 500;
    font-size: large;
    
    padding-left: 10px;
    width: 100%;
    background-color: var(--color-paper);
    color: white;
}
.no-ques{
    border: 2px solid black;
    display: flex;
    height: auto;
    min-height: 200px;
    flex-direction: column;
    background-color: aliceblue;
}
.pal-ques{
    display: flex;
    gap: 5px;
    padding-left: 5px;
    padding-top: 5px;
    flex-wrap: wrap;
    padding-bottom: 5px;
}
.no-qp{
    display: flex;
    gap: 15px;
    padding-left: 5px;
    padding-top: 5px;
    flex-wrap: wrap;
    padding-bottom: 5px
}
.sq,.usq,.mq,.nvq{
    display: flex;
    font-weight: 500;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 100px;
}
.sq{
    background-color: green;
    color: white;
    
}
.usq{
    background-color: red;
    color: white;
}
.mq{
    background-color: rgb(130, 7, 130);
    color: white;
}
.nvq{
    color: black;
    border:  1px solid black;
}
.tqh{
    width: 100%-6px;
    height: 30;
    display: flex;
    justify-content: center;
    color: white;
    font-weight: 500;
    background-color: var(--color-paper);
    margin: 0 3px;
}
.pal-ques .solved-q{
    width: 30px;
    height: 30px;
    display: flex;
justify-content: center;
color: white;
align-items: center;
    background-color: green;
}
.pal-ques .unsolved-q{
    width: 30px;
    height: 30px;
    color: white;
    display: flex;
justify-content: center;
align-items: center;
    background-color: red;
}
.pal-ques .notvisited-q{
    width: 30px;
    height: 30px;
    color: black;
    border: 1px solid black;
    display: flex;
justify-content: center;
align-items: center;
    background-color: white;
}
.solved-q,.unsolved-q,.notvisited-q{
    font-weight: 600;
    cursor: pointer;
    font-size: large;
}
.solved-q:hover,.unsolved-q:hover,.notvisited-q:hover{
    box-shadow: 1px 2px 3px 4px rgba(184, 182, 182, 0.4);
}
.exam-btns-ques{
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
   gap: 5px;
   column-gap: 10px;
}
.exam-btn-que{
    color: white;
    height: 35px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 90px;
    background-color: var(--color-paper);
}
.stu-name{
    font-weight: 600;
}
#siq{
    font-size: medium;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    border: none;
    border-bottom: solid 1px var(--color-paper) ;
}
#siq:focus{
    outline: none;
}

@media screen and (max-width:761px) {
    .main-ques{
        height: max-content;
    }
    .box-ques{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: max-content;
    }
    .ques-leftp{
        width: 95%;
        overflow-x: hidden;
    }
    .ques-sec{
        padding: 5;
    }
    .question-box .ques{
        width: 90%;
    }
    .ques-rightp{
       width: 95%;
       margin: 10px;
       height: max-content;
       position: relative;
       left: 0;
    }
    .option{
        width: 100%;
        height: max-content;
    }
    
    .ques-palette{
        min-height: 0;
        height: max-content;
    }
    .exam-btns-ques{
        justify-content: space-around;
    }
    .no-qp{
        justify-content: space-evenly;
    }
}
@media screen and (max-width:500px) {
    .popup{
        width: 80vw;
        height: 350px;
        top: calc((100vh - 350px)/2);
        left: 10vw;
    }

}
