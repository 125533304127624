/* OTPVerificationPage.css */

/* Page container */

  /* OTP input field */
 .ver-otp-input {
    background-color: rgb(219, 214, 214);
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .otp-boxes{
    display: flex;
    gap: 5px;
    height: 40px;
  }
  .otp-boxes input{
    height: 40px;
    display: flex;
    text-align: center;
    font-weight: 500;
    font-size: 28;
    
    width: 40px;
  }
  
  
  /* Buttons */
  .box-verify button {
    padding: 5px 10px;
    font-size: 16px;
    background-color:  var(--color-primary);;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
  }
  
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  /* Success message */
  .success {
    color: #00cc00;
    font-size: 18px;
  }

  .box-verify{
    padding: 20px;
    gap: 15px;
    position: absolute;
    width: max-content;
    background-color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .otp-header{
    width: 250px;
    text-align: center;
    margin: auto;
    font-weight: 500;
  text-overflow: ellipsis;
}