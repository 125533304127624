.con-legacies{
    margin-bottom: 20px;
    padding: 15px;
   justify-content: center;
   display: flex;
   flex-direction: row;
   align-content: center;
    background-color: #800080;
}
.box-legacies{
    display: flex;
    width: 70%;
    justify-content: space-between;
}
.legacy{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}
.legacy h1,h5{
    margin: auto;
    color: white;
}