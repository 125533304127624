.slider_link ::-webkit-scrollbar {
    width: 0;  
    background: transparent;  
}
.slider_link{
    width: 100%;
    margin-bottom: 70px;
    height: max-content;
    display: flex;
    justify-content: center;
    align-content: center;
}
.box_sec1{
    display: flex;
    height: 400px;
    width: 50%;
    
    width: max-content;
    flex-direction: row;
}
.box_sec1 img{
    width: 100%;
}
.left-sec1{
    
    box-shadow: 1px 2px 3px 4px rgba(184, 182, 182, 0.4);
    padding: 10px;
    border-radius: 10px;
   height: 350px;
   
    width: 330px;
    margin: 10px;
    
    display: flex;
    flex-direction: column;

}
.opts{
    display: flex;
    gap: 10px;
    justify-content: space-between;

}


.opt{
    width: max-content;
    padding: 2px;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid rgb(173, 173, 173);
}
.news{
    margin-top: 10px;
    padding-left: 2px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.new{
    padding: 2px 3px;
    border-radius: 10px;
    background-color: purple;
    color: white;
}
.opt:hover{
    background-color: rgb(217, 215, 215);
}
.right-sec1{
    box-shadow: 1px 2px 3px 4px rgba(184, 182, 182, 0.4);
   
    display: flex;
    margin: 10px;
    border-radius: 11px;
   
    width: 700px;
    height: 420px;
    
    justify-content: center;
    align-content: center;
}
.img-sec1{
    margin: 10px;
    position: relative;
    display: flex;
}
.back,.forward{
    position: absolute;
    scale: 4;
    top: 200px;
    color: gray;
}
.back{
    left: 30px;
}
.forward{
    right: 30px;
}
.right-sec1 img{
    height: 400px;
    object-position: center;
    width: 700px;
    object-fit: cover;
}