
.con-dash-prof{
    display: flex;
    position: absolute;
    right: 20px;
    top: 30px;
}
.dash-prof{
    color: #A3AED0;
    background-color: white;
    display: flex;
    box-shadow: var(--box-shadow);
    gap: 20px;
    padding:8px 10px;
    border-radius: 15px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}
.con-dash-prof .con-msgs{
    position: absolute;
    right: 100px;
    z-index: 1001;
   
    top: 40px;
    background-color: rgb(237, 227, 227);
    height: 300px;
    width: 220px;
    border-radius: 10px;

    box-shadow: var(--box-shadow);

}
.t{
    background-color: rgb(204, 179, 179);
}
.con-msgs h3{
    width: 100%;
    background-color: aliceblue;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    gap: 10px;
}
.con-dash-prof .side-menu{
    display: none;
}
.dash-prof .ic-noti{
    
    justify-content: center;
    position: relative;
}
.search-bar{
    padding-left: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    height: 35px;
    background-color: var(--color-bg);
    border-radius: 10px;
}
.search-bar input{
    background-color: var(--color-bg);
    border: none;
    height: 35px;
    color: black;
   
    border-radius: 10px;
}
.search-bar input:focus{
    outline: none;
}

.ic-noti .io-noti,.io-set{
    scale: 2;
    cursor: pointer;
}
.ic-noti div{
    position: absolute;
    top: -18px;
    height: 20px;
    display: flex;
    cursor: pointer;
    width: 20px;
    font-size: small;
    border-radius: 10px;
    background-color: var(--color-primary);
    color: white;
    justify-content: center;
    align-items: center;
    padding: auto;
    right: -10px;
}
.dash-prof .dp-cir{
    height: 40px;
    width: 40px;
    color: white;
    overflow: hidden;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    background-color: var(--color-primary);
    border-radius: 20px;
}
.dp-cir img{
    background-position: center;
    object-fit: cover;
}
.con-dash{
    position: relative;
    background-color: var(--color-bg);
    height: 100vh;
    width: 100vw;
}
.box-dash{
    padding-top: 20px;
    display: grid;
    
    height: 100%;
    grid-template-columns: 14% 66% 15%;
    width: 96%;
    margin: auto;
}
.logout-popup{
    height: 130px;
    width: 300px;
    
    z-index: 3;
    border-radius: 10px;
    box-shadow: var(--box-shadow);
    overflow: hidden;
   
    background-color: white;
    position: absolute;
}
.theme-popup{
    height: 200px;
    width: 300px;
    top: calc((100% - 200px)/2);;
    z-index: 3;
    display: flex;
    padding: 10px;
   flex-direction: column;
    
    border-radius: 10px;
    box-shadow: var(--box-shadow);
    overflow: hidden;
    left: calc((100% - 300px)/2);;
    background-color:black;
    position: absolute;
}
.theme-popup h3{
    color: white;
  
}
.themes{
    display: grid;
    grid-template-columns: repeat(5,1fr);
}

.overlay{
    
    position: fixed; 
  top: 0;
  z-index: 999;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(128,128,128,0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
}
.logout-head{
    height: 30px;
    color: white;
    
    gap: 50px;
    width: 100%;
    font-size: large;
    padding-left: 10px;
    background-color: var(--color-primary);
}
.confirmation{
    width: 100%;
    margin-top: 15px;
    display: flex;
    font-size: large;
    font-weight: 500;
    justify-content:center ;
}
.confirm-btns{
    margin-top: 10px;
    display: flex;
    width: 100%;
    justify-content: space-around;
}
.no-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 40px;
    color: white;
    width: 40px;
    border-radius: 20px;
    background-color: red;
}
.yes-btn{
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    height: 40px;
    color: white;
    width: 40px;
    border-radius: 20px;
    background-color: var(--color-primary);
}
.con-ic{
    scale: 1.5;
}

aside{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.top{
    padding: 5px;
    
    display: flex;
}
.top .d-logo{
    height: 40px;
    font-size: large;
    display: flex;
    width: max-content;
    justify-content: center;
    align-items: center;
}
.top strong span{
    color: rgb(163, 163, 4);
}
.top .d-logo img{
    height: 50px;
    object-fit: cover;
    width: 60px;
}
.close{
    display: none;
}
.sidebar{
    margin-top: 20px;
    background-color: white;
    display: flex;
    height: 85%;border-radius: 10px;
    overflow: hidden;
    box-shadow: var(--box-shadow);
    gap: 15px;
    flex-direction: column;


}
.sidebar:hover{
    box-shadow: none;
}
.sidebar a:last-child{
    width: 10%;
    cursor: pointer;
    position: absolute;
    bottom: 30px;
}
.sidebar a span,.sidebar a strong{
    color: rgb(71, 68, 68);
}
.sidebar a{
    display: flex;
    height: 50px;
    cursor: pointer;
    width: 100%;
    position: relative;
 justify-content: flex-start;
 align-items: center;
   
}
.sidebar a span{
    margin-left: 20px;
}


.sidebar a strong{
    width: max-content;
    font-weight: 500;
    padding-left: 20px;
    margin: 0;
    font-size: medium;
}
.sidebar a:hover span{
   margin-left: 30px;
}

#active{

    background-color: var(--color-secondary);
}
.sidebar #active::before{
    content: "";
    height: 50px;
    width: 10px;
    background-color: var(--color-primary);
}

.main-dash{
    width: 66%;
    padding: 20px;
}

.box-main{
    
    width: 66%;
    display: flex;
    flex-direction: column;
   
}
.d-cards{
    margin-top: 20px;
    display: flex;
   
    gap: 20px;
   
}
.d-card{
    width:250px;
    padding: 10px;
    border-radius: 15px;
   
    background-color: white;
    box-shadow: var(--box-shadow);
    display: flex;
}
.d-card:hover{
    box-shadow: none;
}
.d-card-left{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.d-card-right{
    display:flex ;
    height: 100%;
    flex-direction: column;
    align-items: center;
}
.d-progress{
    display: flex;
    flex-direction: column;
    position: relative;
}
.number{
    position: absolute;
    top: 20px;
    left: 25px;
    color: white;
}

.card-cir{
    height: 40px;
    width: 40px;
    font-size: large;
    border-radius: 20px;
    color: white;
    background-color: var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}
.main-dash{
    position: relative;
}

@media screen  and (max-width:700px){
    .con-dash{
        width: 100vw;
        overflow-x: hidden;
        min-height: 100vh;
        height: max-content;
        background-color: var(--color-bg);
    }
   
    .con-dash-prof{
       margin-top: 0;
        padding: 15px;
        position: relative;
        right: 0;
        top: 0;
        align-items: center;
       
        width: 100vw;
        justify-content: space-between;
        background-color: white;
    }
    .con-dash-prof .side-menu{
        scale: 2;
        margin-left: 15px;
        display: flex;
    }
    .box-dash{
        width: 100%;
        overflow-x: hidden;
        height: 100%;
    }
    aside{
        
      z-index: 3;
        background-color: white;
        height: 100%;
        position: absolute;
        margin-top: 0;
        top: 0;
        position: fixed;
        left: 0;
    }
    .vis-side{
        display: flex;
    }
    .nvis{
        display: none;
    }
    .dash-prof{
        box-shadow: none;
    }
    
    .box-dash{
        padding-top: 0;
    }
    .top{
        gap: 5px;
        margin-top: 15px;
    }
    .close{
       scale: 1.5;
       
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .sidebar a:last-child{
        margin-top: 15px;
        width: 100%;
        position: relative;
        
    }
    .sidebar{
        border-radius: 0;
        height: 100%;
    }
    .main-dash{
        padding-left: 0;
    }
    .box-main h2{
        margin-left: 20px;
    }
    .d-cards{
        width: 100vw;
        display: flex;
        margin-left: 0;
        flex-direction: column;
    }
    .d-card{
        width: 80%;
        margin: auto;
    }

}