body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  :root{
    --color-bg:#f6f6f9;
    --color-light:rgba(132,139,200,0.18);
    --box-shadow:2px 0 4px 0 #0000001a;
    --color-primary:#4318FF;
    --color-paper:rgb(77, 77, 188);
    --color-secondary:#C6D2FD;
  }
  
  .light-theme{
    --color-bg:#f6f6f9;
    --color-light:rgba(132,139,200,0.18);
    --box-shadow:2px 0 4px 0 #0000001a;
    --color-primary:#2B3674;
    --color-paper:rgb(77, 77, 188);
    --color-secondary:#C6D2FD;
  }
  
  .dark-theme{
    --color-bg:#f6f6f9;
    --color-light:rgba(132,139,200,0.18);
    --box-shadow:2px 0 4px 0 #0000001a;
    --color-primary:rgb(125,7,125);
    --color-paper:rgb(77, 77, 188);
    --color-secondary:#f6c2e7;
  }
  .green-theme{
    --color-bg:#f6f6f9;
    --color-light:rgba(132,139,200,0.18);
    --box-shadow:2px 0 4px 0 #0000001a;
    --color-primary:#04CD00;
    --color-paper:rgb(77, 77, 188);
    --color-secondary:#BCEFBD;
  }